import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "about-banners",
      "style": {
        "position": "relative"
      }
    }}>{`About Banners`}</h2>
    <p>{`In-page banners appear inline and are displayed in an non-blocking way. They
should be used to alert users about important information.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=490%3A81" mdxType="FigmaEmbed" />
    <p><strong parentName="p">{`1) Icon`}</strong>{`: Conveys more meaning to the message.`}</p>
    <p><strong parentName="p">{`2) Message`}</strong>{`: Provide a clear and short description of the alert. Include an
appropriate action or suggest next steps to help the user.`}</p>
    <p><strong parentName="p">{`3) Dismiss action`}</strong>{`: An optional dissmiss icon button.`}</p>
    <h2 {...{
      "id": "variatons",
      "style": {
        "position": "relative"
      }
    }}>{`Variatons`}</h2>
    <p>{`Banners should be used sparingly to communicate cleary the severity of the issue
or situation.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=491%3A0
" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "informational-banner",
      "style": {
        "position": "relative"
      }
    }}>{`Informational banner`}</h3>
    <p>{`Communicates additional information without requiring an action. Informational
states are always being displayed in `}<inlineCode parentName="p">{`supportive-blue`}</inlineCode>{` colors.`}</p>
    <h3 {...{
      "id": "succcess-banner",
      "style": {
        "position": "relative"
      }
    }}>{`Succcess banner`}</h3>
    <p>{`Communicates positive feedback to user actions. Success message states are
always being displayed in `}<inlineCode parentName="p">{`supportive-green`}</inlineCode>{` colors.`}</p>
    <h3 {...{
      "id": "error-banner",
      "style": {
        "position": "relative"
      }
    }}>{`Error banner`}</h3>
    <p>{`Communicates negative feedback to user actions. Always try to explain the
problem in a understandable human way and provide potential next steps to
resolve the situation. Error message states are always being displayed in
`}<inlineCode parentName="p">{`supportive-red`}</inlineCode>{` colors.`}</p>
    <h2 {...{
      "id": "banner-size",
      "style": {
        "position": "relative"
      }
    }}>{`Banner size`}</h2>
    <p>{`The size of a banner is responsive to the browser window. On smaller screen the
banner text should wrap onto multiple lines.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=491%3A116
" mdxType="FigmaEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      